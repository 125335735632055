






















































































































import Vue from 'vue';
import { MetaInfo } from 'vue-meta';
// @ts-ignore
import LazyHydrate from 'vue-lazy-hydration';
import { throttle } from 'throttle-debounce';
import { getISODateString } from '@devhacker/shared/utils/date';
import { trackSelfDescribingEvent } from '@snowplow/browser-tracker';
import focusVisibleInstance from '~/plugins/focus-visible';
import { GET_AB_TITLE_BY_ARTICLE } from '~/constants';
import App from '~/app/App.vue';

import NavMenuContainer from '~/containers/NavMenuContainer/NavMenuContainer.vue';
import TopHeaderContainer from '~/containers/TopHeaderContainer/TopHeaderContainer.vue';
import PrimaryMenu from '~/components/PrimaryMenu.vue';
import WidgetsRendererContainer from '~/containers/WidgetsRendererContainer.vue';
import NavMenuBelowHeaderContainer from '~/containers/NavMenuBelowHeaderContainer';
import TheTvContainer from '~/containers/TheTvContainer/TheTvContainer.vue';
import ContentWrapperForBranding from '~/components/ContentWrapperForBranding.vue';

import makePreloadLinksForArticleCardCover from '~/libs/makePreloadLinksForArticleCardCover';
import { trailingSlash } from '~/utils';
import type { Menus } from '~/store/menus';
import { isArchiveByDatePage, isHomePage, isArticlePage, isPreviewPage, isHealthPath, isHealthMain } from '~/router';
// Если сделать динамический импорт появляется ошибка LH-1424
import PartnerBannerContainer from '~/containers/PartnerBannerContainer';
import {
  percentageInit,
  viewedContentPercent,
  getContentObject,
  viewedPagePercent,
  getPageObject,
} from '~/utils/PercentagePageElement';
import { YA_METRIKA_ID } from '~/constants/config';
import { ADS_EXCEPTIONS } from '~/constants/ads-labels';

const JumperContainer = () => import('~/containers/JumperContainer/JumperContainer.vue' /* webpackChunkName: 'JumperContainer' */);
const TheFooterContainer = () => import('~/containers/TheFooterContainer.amp' /* webpackChunkName: 'TheFooterContainer' */);
const PromoContainer = () => import('~/containers/PromoContainer.vue' /* webpackChunkName: 'PromoContainer' */);

const ERROR_TEMPLATE_CLASS = 'error-template-two-column';

export default Vue.extend({
  name: 'DefaultLayout',

  components: {
    App,
    LazyHydrate,
    PromoContainer,
    NavMenuContainer,
    TopHeaderContainer,
    PrimaryMenu,
    WidgetsRendererContainer,
    JumperContainer,
    TheFooterContainer,
    PartnerBannerContainer,
    NavMenuBelowHeaderContainer,
    TheTvContainer,
    ContentWrapperForBranding,
  },

  data: () => ({
    isRerender: false,
    metaSingleArticle: [
      {
        hid: 'lh_author',
        name: 'lh_author',
        content: 'null' as string,
      },
      {
        hid: 'lh_category',
        name: 'lh_category',
        content: 'null' as string,
      },
      {
        hid: 'lh_stream',
        name: 'lh_stream',
        content: 'null' as string,
      },
      {
        hid: 'lh_tag',
        name: 'lh_tag',
        content: 'null' as string,
      },
      {
        hid: 'lh_partner',
        name: 'lh_partner',
        content: 'null' as string,
      },
      {
        hid: 'lh_published_time',
        name: 'lh_published_time',
        content: 'null' as string,
      },
      {
        hid: 'lh_first_published_time',
        name: 'lh_first_published_time',
        content: 'null' as string,
      },
    ],
  }),

  head (): MetaInfo {
    const { name } = this.$route;

    // Превью закрыты от роботов, остальное сео не нужно
    if (isPreviewPage(name)) {
      return {
        meta: [
          {
            hid: 'robots',
            name: 'robots',
            content: 'noindex,nofollow',
          },
        ],
      };
    }

    const isSingleArticle: boolean = (name === 'single') || (name === 'preview');
    const { title, description, robots, canonical, schema_graph = '', og: { image } = {}, schema_recipe, schema_howto } = this.$store.getters.currentSeo as Seo;
    const ldJsonContent: string = schema_graph.replace(/<script.*?>(.*)<\/script>/, '$1');

    const article = this.$store.getters.currentArticle as null | ISingleArticle;
    let abTitle: null | string = null;

    if (isSingleArticle && article) {
      abTitle = this.$store.getters[GET_AB_TITLE_BY_ARTICLE](article?.id);

      const { categories, author: { name }, date, isAdvertising, isPromo, tags } = article;
      this.setMetaSingleArticleContent('lh_author', name);

      const firstTopic = categories.find((item): boolean => item.url.includes('topics/'));
      const firstTopicTitle = firstTopic?.title ?? 'null';
      this.setMetaSingleArticleContent('lh_category', firstTopicTitle);

      const firstStream = categories.find((item): boolean => item.url.includes('stream/'));
      const firstStreamTitle = firstStream?.title ?? 'null';
      this.setMetaSingleArticleContent('lh_stream', firstStreamTitle);

      const technicalTags: string[] = ['noad', 'nozen', 'hide', 'noturbo', 'noadsense'];
      const notTechnicalTags = tags.filter((item): boolean => !technicalTags.includes(item.title));
      const firstTagTitle = notTechnicalTags[0]?.title ?? 'null';
      this.setMetaSingleArticleContent('lh_tag', firstTagTitle);

      const partnerValueToSet = isAdvertising || isPromo ? 'Партнёрский материал' : 'null';
      this.setMetaSingleArticleContent('lh_partner', partnerValueToSet);

      const ISODate = getISODateString(new Date(1000 * date));
      this.setMetaSingleArticleContent('lh_published_time', ISODate);

      const firstPublishedDate = article?.meta?.lh_first_published_time || null;
      const ISOFirstPublishedDate = firstPublishedDate !== null ? getISODateString(new Date(firstPublishedDate * 1000)) : 'null';
      this.setMetaSingleArticleContent('lh_first_published_time', ISOFirstPublishedDate);
    }

    const link = [
      { hid: 'canonical', rel: 'canonical', href: canonical },
      { hid: 'image_src', rel: 'image_src', href: image },
    ];
    if (article?.ampEnabled) {
      link.push({
        hid: 'amphtml',
        rel: 'amphtml',
        href: `${trailingSlash(canonical)}amp/`,
      });
    }

    if (process.server) {
      const preloadCoversLinks: any = makePreloadLinksForArticleCardCover(
        name,
        this.$store.getters.currentArticles,
        article,
      );
      link.push(...preloadCoversLinks);
    }

    const getTitle = (): string => {
      if (this.isErrorPage) {
        return 'Страница не найдена - Лайфхакер';
      }
      return abTitle || title;
    };

    return {
      link,
      title: getTitle(),
      meta: [
        { hid: 'og:title', property: 'og:title', content: title },
        { hid: 'og:image', property: 'og:image', content: image ?? '' },
        { hid: 'og:description', property: 'og:description', content: description },
        { hid: 'og:url', property: 'og:url', content: canonical },
        { hid: 'description', name: 'description', content: description },

        // twitter
        { hid: 'twitter:title', property: 'twitter:title', content: title },
        { hid: 'twitter:description', property: 'twitter:description', content: description },
        { hid: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
        { hid: 'twitter:image', property: 'twitter:image', content: image ?? '' },

        {
          hid: 'robots',
          name: 'robots',
          // В архивах по датам нужно отключить индексацию https://youtrack.lifehacker.ru/issue/LH-1042
          content: isArchiveByDatePage(name) ? 'noindex, nofollow' : robots,
        },

        // LH-1552
        {
          hid: 'lh_page_title',
          name: 'lh_page_title',
          content: title,
        },

        ...this.metaSingleArticle,
      ],
      script: [
        ...(ldJsonContent
          ? [
            {
              type: 'application/ld+json',
              class: 'yoast-schema-graph yoast-schema-graph--main',
              innerHTML: ldJsonContent,
            },
          ]
          : []),
        ...(article?.jsonLD
          ? [
            {
              type: 'application/ld+json',
              innerHTML: article?.jsonLD,
            },
          ]
          : []),
        ...(schema_recipe
          ? [
            {
              type: 'application/ld+json',
              class: 'yoast-schema-graph yoast-schema-graph--main',
              innerHTML: schema_recipe,
            },
          ]
          : []),
        ...(schema_howto
          ? [
            {
              type: 'application/ld+json',
              innerHTML: schema_howto,
            },
          ]
          : []),
      ],
      __dangerouslyDisableSanitizers: ['script'],
    };
  },

  computed: {
    isArticleTypePost (): boolean {
      return this.$store.getters.isArticleTypePost;
    },

    leftSidebarAndOtherContent (): any {
      return this.isRerender ? 'left-sidebar-and-other-content--rerender' : null;
    },
    primaryNavMenuItems (): Array<MenuItem> {
      return (this.$store.getters.menus as Menus)?.projects?.items ?? [];
    },
    widgetsAboveHeader (): Array<string> {
      return this.$store.getters.currentAboveHeaderWidgets;
    },
    sidebarWidgets (): Array<TWidget> {
      // return this.isHealth ? this.$store.getters.currentHealthSidebar : this.$store.getters.currentSidebarWidgets;
      return this.$store.getters.currentSidebarWidgets;
    },
    isHealthPath (): boolean {
      return isHealthPath(this.$route.path);
    },
    leftSidebarWidgets (): Array<TWidget> {
      return this.$store.getters.currentLeftSidebarWidgets;
    },
    widgetsBelowHeader (): Array<string> {
      return this.$store.getters.currentBelowHeaderWidgets;
    },
    pageClasses (): Array<string> {
      const { name } = this.$route;
      const { isSingleArticle, isJumperPost, isErrorPage, isHealthAboutProjectPage, isHealthExpertsPage } = this;

      if (isErrorPage) {
        return [ERROR_TEMPLATE_CLASS];
      }

      const template = this.article ? this.article?.template : (isHealthAboutProjectPage || isHealthExpertsPage) ? 'one-column' : 'none';
      const classes = [];

      classes.push(`route-${name ?? ''}`);

      (isSingleArticle || isHealthAboutProjectPage || isHealthExpertsPage) ? classes.push(`template-${template}`) : classes.push('route-archive');
      isJumperPost && classes.push('jumper-post');
      this.$store.getters.isArticleTypePage && classes.push('type-page');

      return classes;
    },
    article (): ISingleArticle | null {
      return this.$store.getters.currentArticle;
    },
    isHomePage (): boolean {
      return isHomePage(this.$route.name);
    },
    isSingleArticle (): boolean {
      // todo: после того как будет готово API для определения
      // страницы/категории/подкатегории добавить сюда
      if (isHealthMain(this.$route.name)) { return false; }

      return (
        isArticlePage(this.$route.name) ||
        isPreviewPage(this.$route.name) ||
        this.$store.getters.isHealthArticle ||
        this.$store.getters.isHealthPage
      );
    },
    // отображаем "правый сайдбар", если это "Главная/Архивы" ИЛИ "Внутренняя" с шаблоном "2/3 колонки"
    isRightSidebarVisible (): boolean {
      const article = this.$store.getters.currentArticle;

      if (this.isHealthAboutProjectPage || this.isHealthExpertsPage) { return false; }

      return this.isErrorPage || !this.isSingleArticle || (article?.template && ['two-column'].includes(article?.template));
    },

    isErrorPage (): boolean {
      // в типах почему-то не прописано это свойство
      // @ts-ignore
      return this.$nuxt.nuxt.err;
    },

    isHealthAboutProjectPage (): boolean {
      return this.$route.name === 'health-about-project';
    },

    isHealthExpertsPage (): boolean {
      return this.$route.name === 'health-experts';
    },

    isJumperPost (): boolean {
      return this.isSingleArticle && this.$store.getters.isJumperPost;
    },

    isShowFooter (): boolean {
      return !this.isJumperPost;
    },

    // Специальный раздел в вп "страницы" для статей, которые не попадают в ленту
    isArticleTypePage (): boolean {
      return this.isSingleArticle && this.$store.getters.isArticleTypePage;
    },

    isAdvertisingPost (): boolean {
      return this.isSingleArticle && this.$store.getters.isAdvertisingPost;
    },
    isPromoArticle (): boolean {
      return this.$store.getters.isPromo;
    },
    isAdsException (): boolean {
      return ADS_EXCEPTIONS.includes(this.$store.getters.currentArticle?.advertLabel);
    },
    belowCommentsWidgets (): TWidget {
      return this.$store.getters.currentBelowCommentsWidgets;
    },
    footerWidgets (): TWidget {
      return this.$store.getters.currentFooterWidgets;
    },
    isBelowCommentsWidgetVisible (): boolean {
      const {
        isSingleArticle,
        isPromoArticle,
        isAdvertisingPost,
        isErrorPage,
        isArticleTypePage,
        isJumperPost,
      } = this;
      return isSingleArticle &&
          !isAdvertisingPost &&
          !isPromoArticle &&
          !isErrorPage &&
          !isArticleTypePage &&
          !isJumperPost;
    },
    isFooterWidgetVisible (): boolean {
      const {
        isSingleArticle,
        isPromoArticle,
        isAdvertisingPost,
        isErrorPage,
        isArticleTypePage,
        isJumperPost,
      } = this;
      return isSingleArticle &&
          !isAdvertisingPost &&
          !isPromoArticle &&
          !isErrorPage &&
          !isArticleTypePage &&
          !isJumperPost;
    },
    routePath (): string {
      return this.$route.path;
    },
  },

  watch: {
    // https://youtrack.lifehacker.ru/issue/LH-1204
    // при смене роута скрываем контент на небольшое время,
    // тк некоторые блоки, которые появляются/исчезают на
    // новом или текущем роуте, могут ломать верстку
    routePath (): void {
      this.isRerender = true;
      setTimeout(() => {
        this.isRerender = false;
      }, 200);
      // сброс мета тегов
      this.metaSingleArticle.forEach((meta) => {
        meta.content = 'null';
      });
    },
  },

  mounted () {
    document.addEventListener('click', this.handleAnyClick, false);
    document.addEventListener('beforeinstallprompt', this.handleClickOnBanner);
    focusVisibleInstance.focusVisibleInit();

    // * analytics pagescroll content
    percentageInit('.single-article__content');
    percentageInit();

    const throttledOnScroll = throttle(100, this.onScroll);
    window.addEventListener('scroll', throttledOnScroll);

    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('scroll', throttledOnScroll);
    });
  },

  beforeDestroy () {
    document.removeEventListener('click', this.handleAnyClick, false);
  },

  methods: {
    onScroll (): void {
      const contentPercent = viewedContentPercent();
      const pagePercent = viewedPagePercent();

      if (contentPercent) { this.percentageSendAnalytics(getContentObject(), contentPercent, 'Скролл материала', true); }
      if (pagePercent) { this.percentageSendAnalytics(getPageObject(), pagePercent, 'Скролл страницы'); }
    },
    percentageSendAnalytics (typeCategory: any, newValue: any, eventYm: string, isContentScroll: boolean = false) {
      const { thresholds, key, category } = typeCategory;

      const viewedThresholds = Object.keys(thresholds).filter(
        threshold => threshold <= newValue && !thresholds[threshold],
      );

      viewedThresholds.forEach((threshold) => {
        thresholds[threshold] = true;

        const scrollEventParams = {
          [key]: {
            event: category,
            value: threshold.toString(),
          },
        };
        window.ym(YA_METRIKA_ID, 'params', scrollEventParams);

        this.$sendYandexMetrika({
          level1: eventYm,
          level4: threshold.toString(),
        });

        // Пока отключен глобальный сбор событий сноуплоу
        // TODO: Заменить на sendAnalyticsSnowPlow если он включен
        isContentScroll && trackSelfDescribingEvent({
          event: {
            schema: 'iglu:dev.snowplow.simple/custom_data/jsonschema/1-0-0',
            data: {
              postScroll: threshold.toString(),
            },
          },
        });
      });
    },
    handleClickOnBanner (e: any) : void {
      e.prompt();
      e.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {}
        document.removeEventListener('beforeinstallprompt', this.handleClickOnBanner);
      });
    },
    handleAnyClick (event: MouseEvent): void {
      const anchor = event
        .composedPath().find((item: EventTarget) => item instanceof HTMLAnchorElement);

      if (anchor instanceof HTMLAnchorElement) {
        const { href } = anchor;
        const { location: { hostname } } = window;

        const parserLink = document.createElement('a');
        parserLink.href = href;

        if (!parserLink.hostname.includes(hostname)) {}
      }
    },

    setMetaSingleArticleContent (hid: string, content: string): void {
      const target = this.metaSingleArticle.find(meta => meta.hid === hid);
      if (target) {
        target.content = content;
      }
    },
  },
});
