




























import Vue, { PropOptions } from 'vue';
import { NuxtError } from '@nuxt/types';
import { MetaInfo } from 'vue-meta';
import GoogleOneTapAuth from '~/components/GoogleOneTapAuth/GoogleOneTapAuth.vue';
import getSlugFromUrl from '~/utils/getSlugFromUrl';
import { isHealthPath } from '~/router';

type Link = {
  id: number;
  url: string;
  title: string;
};

type Data = {
  links: Link[] | void;
};

export default Vue.extend({
  name: 'ErrorPage',

  components: {
    GoogleOneTapAuth,
  },

  layout (context) {
    return isHealthPath(context.route.path) ? 'health' : 'error';
  },

  props: {
    error: {
      type: Object,
      required: true,
    } as PropOptions<NuxtError>,
  },
  data (): Data {
    return {
      links: [],
    };
  },
  async fetch () {
    if (this.error?.statusCode === 404) {
      try {
        this.links = await this.fetchLinks();
      } catch {}
    }
  },

  head (): MetaInfo {
    if (this.isHealth) {
      return {
        meta: [
          {
            hid: 'robots',
            name: 'robots',
            content: 'noindex, nofollow',
          },
        ],
      };
    }
    return {};
  },

  computed: {
    isHealth (): boolean {
      return isHealthPath(this.$route.path);
    },

    imageUrl404 (): string {
      return require(this.isHealth
        ? '~/assets/images/error-404-health.svg'
        : '~/assets/images/error-404.svg');
    },
  },

  methods: {
    getSlugFromUrl,

    async fetchLinks (): Promise<void | Link[]> {
      const isHealth = isHealthPath(this.$route.path);

      if (isHealth) {
        try {
          const response = await this.$healthApi.fetchHome(3);
          return response.data.items.map(
            ({ title, url, id, teaserUrl }: IArticle) => ({
              title,
              url,
              id,
              teaserUrl,
            }),
          );
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Error while fetchLastHealthArticles(3)');
          throw new Error('Error while fetchLastHealthArticles(3)');
        }
      } else {
        return this.$ironMaidenApi
          .fetchLastNArticles(3)
          .then(({ status, data }) => {
            if (status === 200) {
              const articles: IArticle[] = data.items;
              return articles.map(
                ({ title, url, id, teaserUrl }: IArticle) => ({
                  title,
                  url,
                  id,
                  teaserUrl,
                }),
              );
            }
            // eslint-disable-next-line no-console
            console.error('Error while fetchLastNArticles(3)');
            throw new Error('Error while fetchLastNArticles(3)');
          })
          .catch(() => []);
      }
    },
  },
});
